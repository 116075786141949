.box {
  border: 1px solid;
  padding: 10px;
  box-shadow: 1px 1px #888888;
}

.App {
  width: 52%;
  margin: 0px auto;

  text-align: center;
  margin-top: 20px;
  padding: 20px;
  background-color: white;
}

.text {
  text-align: center;
  margin-top: 20px;
}

.buttonPdf {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.borders {
  display: flex;
  justify-content: center;
  width: 98%;
  margin-left: 15px;
}

.tag-p {
  text-align: left;
  font-weight: bold;
}

.tag-p-no-bold {
  text-align: left;
}

.login-item {
  height: 100vh;
  width: 40%;
  margin: 0px auto;
  align-items: center;
  display: flex;
}
